import React, { useContext } from "react";
import { Container, Grid, Box, Button } from "@mui/material";
import DetailsSection from "./DetailsSection";
import styles from "./InfoCardix.module.css";
import {
  CardsWithEmploymentEng,
  CardsWithEmploymentPl,
} from "./EmploymenHistoryCardsWithData";
import { LanguageContext, LanguageContextProps } from "../styles/ThemeMode";
import { EmberModeContext, EmberModeContextProps } from "../styles/EmberMode";
import InterestsAndWhoAmI from "./InterestsAndWhoAmI";
import DetailsSectionEmberMode from "./DetailsSectionEmberMode";
import ProjectsToDisplay from "./ProjectsToDisplay";

const InfoCardix: React.FC = () => {
  const { language, setLanguage } = useContext<LanguageContextProps>(LanguageContext);
  const { isEmberMode } = useContext<EmberModeContextProps>(EmberModeContext);
  const isEnglish: boolean = language === "en";

  const toggleLanguage = () => {
    const newLanguage: string = isEnglish ? "pl" : "en";
    setLanguage(newLanguage);
  };

  const cardContainerClass = isEmberMode
    ? `${styles.cardContainer} ${styles.emberMode}`
    : styles.cardContainer;

  const buttonStyles = {
    color: isEmberMode ? 'white' : 'rgba(255, 255, 255, 1)',
    backgroundColor: isEmberMode ? ' #ff6600' : '#8b6ea4',
    '&:hover': {
      backgroundColor: isEmberMode ? '#ffae42' : '#ffae42',
      opacity: 1,
      color: isEmberMode ? 'white' : 'rgba(255, 255, 255, 1)',
    },
    transition: 'transform 1.1s ease-in-out',
    transform: isEnglish ? 'translateX(calc(100% - 160px))' : 'translateX(0)',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1,
    '@media(max-width:280px)': {
      right:'100px',
      bottom:'15px',
      fontSize:'8px',
      margin:'0px 10px'
    }
  };

  return (
    <Container className={`${styles.card} ${isEmberMode ? styles.emberMode : ""}`}>
      <Grid container spacing={2}>
        <Grid item md={isEmberMode ? 0 : 4}>
        {isEmberMode ? 
           <ProjectsToDisplay /> : <DetailsSection language={language} />
        }
        </Grid>
        <Grid item md={8}>
          <Box className={cardContainerClass}>
          {isEmberMode ? <DetailsSectionEmberMode  language={language}/> : 
            <Box className={styles.scrollContainer}>
        
              {isEnglish ? <CardsWithEmploymentEng /> : <CardsWithEmploymentPl />}
            
              </Box>
            }
          </Box>
          {!isEmberMode &&
          <Button
            onClick={toggleLanguage}
            sx={buttonStyles}
          >
            {isEnglish ? "Zmień na polski" : "Switch to English"}
          </Button>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoCardix;
