import React, { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styles from "./Header.module.css";
import logoImage from "../OurbodyisloveOldLogo.png";
import logoImageEmberMode from "../OurbodyisloveOldLogoEmberMode.png";
import { EmberModeContext } from "../styles/EmberMode";
import BonfireGif from "./BonfireGif";
import instagramLogo from "./instagram-logo.png";

const Header = () => {
  const { isEmberMode, toggleEmberMode } = useContext(EmberModeContext);
  const [isObilClicked, setIsObilClicked] = useState(false);

  const handleObilClick = () => {
    setIsObilClicked(!isObilClicked);
  };

  return (
    <header
      className={`${styles.header}${isEmberMode ? ` ${styles.emberMode}` : ""}`}
    >
      <Box className={styles.headerLogo}>
        {isEmberMode ? (
          <span className={styles.headerBonfire}>
            <Button>
              <Typography
                component="a"
                href="https://www.instagram.com/ourbodyislove"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.hoverText}
                style={{ textDecoration: "none", marginTop: "10px" }}
              ></Typography>
              <BonfireGif />
            </Button>
          </span>
        ) : (
          <Box className={styles.logoContainer}>
            <img
              src={logoImage}
              alt="Logo"
              onClick={handleObilClick}
              style={{ width: "120px", height: "120px", cursor: "grab" }}
            />
          </Box>
        )}
      </Box>
      <div className={styles.headerNavigation}>
        <Button
          variant="contained"
          className={styles.navigationButton}
          onClick={toggleEmberMode}
        >
          <Typography
            sx={{
              fontSize: "15px",
              "@media (min-width:260px) and  (max-width: 767px)": {
                fontSize: "10px",
              },
            }}
          >
            {isEmberMode ? "Extinguish" : "Glow the Ember"}
          </Typography>
        </Button>
      </div>
    </header>
  );
};

export default Header;
