import React from "react";
import { Link, Typography, Box } from "@mui/material";
import styles from "./ProjectDisplay.module.css";
import caduceus from "./CADUCEUS_BLUE.png";
const ProjectsToDisplay = () => {
  return (
    <>
      <Box className={styles.projectContainer}>
        <Typography variant="h5">Check my projects</Typography>

        <Typography variant="body1" sx={{ padding: "20px 65px" }}>
          <Link
            href="https://github.com/AmplifiedAnima/MedoppRepository"
            underline="none"
          >
            {" "}
            <img src={caduceus} />
            <br />
            <Typography sx={{ marginTop: "10px" }}>Github link</Typography>
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default ProjectsToDisplay;
