import React, { useContext } from "react";
import { Box } from "@mui/material";
import styles from "./DetailsSection.module.css";

import { EmberModeContext, EmberModeContextProps } from "../styles/EmberMode"; // Import EmberModeContext

interface DetailsSectionProps {
  language: string;
}

const DetailsSectionEmberMode: React.FC<DetailsSectionProps> = ({
  language,
}) => {
  const isEnglish = language === "en";
  const { isEmberMode } = useContext<EmberModeContextProps>(EmberModeContext); // Access isEmberMode from the context

  return (
    <Box>

    </Box>
  );
};

export default DetailsSectionEmberMode;
