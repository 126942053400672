import React from 'react';
import image from "./BonfireGifGlowing.gif";

const BonfireGif = () => {
  return (
    <div>
      <img
        src={image}
        alt="Dark Souls Bonfire"
        width="70"
        height="70"
      />
    </div>
  );
};

export default BonfireGif;
