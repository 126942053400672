import React, { useContext } from "react";
import { Box } from "@mui/material";
import styles from "./DetailsSection.module.css";
import image from "./CzupaSamurai.jpg";
import { AllTheDetailsEng } from "./AllTheDetailsEng";
import { AllTheDetailsPl } from "./AllTheDetailsPl";
import { EmberModeContext, EmberModeContextProps } from "../styles/EmberMode"; // Import EmberModeContext

interface DetailsSectionProps {
  language: string;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({ language }) => {
  const isEnglish = language === "en";
  const { isEmberMode } = useContext<EmberModeContextProps>(EmberModeContext); // Access isEmberMode from the context

  return (
    <Box className={`${styles.container} ${isEmberMode ? styles.emberMode : ""}`}>
      <Box mt={2}>
        <div className={styles.imagebox}>
          <img src={image} alt="image should be here" className={styles.image} />
        </div>
        {isEnglish ? <AllTheDetailsEng /> : <AllTheDetailsPl />}
      </Box>
    </Box>
  );
};

export default DetailsSection;
