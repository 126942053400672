import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { LanguageProvider } from "./styles/ThemeMode";
import { EmberModeProvider } from "./styles/EmberMode";
import { BloodTingemodeProvider } from "./styles/BloodTingeMode";
ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <EmberModeProvider>
        <App />
      </EmberModeProvider>
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
