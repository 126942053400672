import EmploymentHistoryCard from "./EmploymentHistoryCard"
export const  CardsWithEmploymentEng = () => {
    return (
        <>
            <EmploymentHistoryCard
                title="Medical interpreter (Polish-English)"
                company="LanguageLine Solutions"
                duration="May 2021 - Now"
                location="Remote"
                description={`
                Interpreting English - Polish (OPI - Over the phone interpreting)
                conversations in real-time for:
                - healthcare,
                - official institutions,
                - police,
                - emergency services,
                - social services,
                - finance institutions,
                - institutions related to the infrastructure of the given area,
                - insurance companies,
                - airports,
                - custody,
                - online platforms
                `}
            />
            <EmploymentHistoryCard
                title="Strength & Conditioning coach"
                company="Self-employed"
                duration="September 2019 - Now"
                location="Poland"
                description={`
                - organizing and conducting workouts,
                group activities for adults and children,
                - strength & conditioning sessions,
                - working on bio-motor abilities with amateur, and semi-professional athletes,
                - yoga classes / soft tissue incentive
                - working with clients  in a group of (4 to 6 people)
                - 1 on 1 Personal training.
                `}
            />

            <EmploymentHistoryCard
                title="Insulation labour worker / driller"
                company="Pluimers Isolatie"
                duration="October 2017 - August 2019"
                location="Rijssen, NL"
                description={`
                - Travelling around Netherlands, Germany, and Belgium
                - Completing various contracts (physical labour as a driller).
                - Worked at times, on a ladder which could be extended to 7.5m.
                - Worked at times in hazardous weather conditions .
                - Operated a hoogwerker (skyworker) in the Netherlands.
                `}
            />

            <EmploymentHistoryCard
                title="Freelance Polish-English Interpreter"
                company="Self-employed / D.A Languages"
                duration="March 2017 - July 2017"
                location="London, UK"
                description={`
                F2F Polish-English interpretation services on a freelance basis.
                Interpreted conversations in real-time for various clients in:
                - healthcare,
                - official institutions(council, citzen advice bureau etc.),
                - social services.
                `}
            />
            <EmploymentHistoryCard
                title="CNC saw operator (Stonemason)"
                company="Stone Masters"
                duration="May 2016 – September 2017"
                location="Perivale, London/UK"
                description={`
                - Making all sorts of different stone-made kitchen Countertops (Quartz, Marble, Granite, etc.).
                - Reading technical images and specifications to determine the required cuts.
                - Using specialized software to adjust the photo of a slab for precise cutting.
                - Operating CNC saw to cut the countertop with minimal waste, optimizing material usage.
                `}
                />
            <EmploymentHistoryCard
                title="Pawnbroker"
                company="Pawnshop Black"
                duration="April 2014 – October 2015"
                location="Koszalin, Poland"
                description={`
                - Estimating the value of products.
                - Loaning money for items and dealing with the repercussions of the decision made prior.
                - Buying and selling gold jewellery.
                `}
            />
    </>
)}

export const CardsWithEmploymentPl = () => {

    return (
        <>
            <EmploymentHistoryCard
                title="Tłumacz medyczny (język polski - angielski)"
                company="LanguageLine Solutions / Samozatrudniony przez Kelly Services Poland Sp. z o.o"
                duration="Maj 2021 - Obecnie"
                location="Zdalnie"
                description={`
                Tłumaczenie rozmów w czasie rzeczywistym 
                z języka angielskiego na polski (OPI - Over the Phone Interpreting)
                dla:
                
                - służby zdrowia,
                - instytucji państwowych,
                - policji,
                - służb ratunkowych,
                - służb społecznych,
                - instytucji finansowych,
                - instytucji związanych z infrastrukturą danego obszaru,
                - firm ubezpieczeniowych,
                - lotnisk,
                - zakładów karnych,
                - platform internetowych.
                `}
            />
        
            <EmploymentHistoryCard
                title="Trener przygotowania motorycznego"
                company="Samozatrudniony"
                duration="Wrzesień 2019 - Obecnie"
                location="Polska"
                description={`
                - Organizacja i prowadzenie treningów,
                - Zajęcia grupowe dla dzieci,
                - Treningi siłowe i kondycyjne,
                - Praca nad sesjami treningowymi z amatorskimi sportowcami,
                - Prowadzenie zajęć jogi,
                - Treningi ruchowo-stawowe,
                - Praca z klientami w grupach 4-6 osób lub indywidualnie.
                `}
            />        
            <EmploymentHistoryCard
            title="Pracownik izolacji / wiertacz"
            company="Pluimers Isolatie"
            duration="Październik 2017 - Sierpień 2019"
            location="Rijssen, NL"
            description="
            Podróże po Holandii, Niemczech i Belgii - 
            realizacja różnych kontraktów (fizyczna praca jako pracownik fizyczny)."
            />
        
            <EmploymentHistoryCard
                title="Wolny tłumacz języka polskiego-angielskiego"
                company="Samozatrudniony w D.A Languages"
                duration="Marzec 2017 - Lipiec 2017"
                location="Londyn, UK"
                description={`
                - Świadczenie usług tłumaczenia twarzą w twarz 
                - z języka polskiego na angielski na zlecenie. 
                - Tłumaczenie rozmów w czasie rzeczywistym dla:
                - służby zdrowia,
                - instytucji państwowych (np. sprawy urzędowe),
                - głównie w służbach społecznych.
                `}
            />

            <EmploymentHistoryCard
                title="Operator piły CNC (kamieniarz)"
                company="Stone Masters"
                duration="Maj 2016 - Wrzesień 2017"
                location="Perivale, Londyn/UK"
                description={`
                - Wykonywanie różnych blatów kuchennych z kamienia (kwarc, marmur, granit itp.).
                - Obsługa  piły 3-osiowej piły CNC.
                `}
            />
            <EmploymentHistoryCard
                title="Lombardzista"
                company="Pawnshop Black"
                duration="Kwiecień 2014 - Październik 2015"
                location="Koszalin, Polska"
                description={`
                - Oszacowywanie wartości produktów,
                - Udzielanie pożyczek pod zastaw i podejmowanie decyzji dotyczących konsekwencji.
                - Kupno i sprzedaż biżuterii złotej.
                `}
            />
        </>
    )
}