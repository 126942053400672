import React from "react";
import { Typography, Box } from "@mui/material";
import styles from "./EmploymentHistoryCard.module.css";

interface EmploymentHistoryCardProps {
  title: string;
  company: string;
  duration: string;
  location: string;
  description: string;
}

const EmploymentHistoryCard: React.FC<EmploymentHistoryCardProps> = ({
  title,
  company,
  duration,
  location,
  description,
}) => {
  const paragraphs = description.split("\n");

  return (
    <Box mt={2} className={styles.cardContainer}>
      <Typography variant="h5" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={styles.company}>
        {company}
      </Typography>
      <Typography variant="body1" className={styles.duration}>
        {duration} | <span className={styles.location}>{location}</span>
      </Typography>
      <Box className={styles.description}>
        {paragraphs.map((paragraph, index) => (
          <Typography variant="body2" key={index} paragraph>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default EmploymentHistoryCard;
