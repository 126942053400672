import React, { useState, useEffect } from "react";
import styles from "./App.module.css";
import InfoCardix from "./components/InfoCardix";
import Header from "./components/Header";
import { Box, Button, Table } from "@mui/material";

function App(): JSX.Element {
  const [isInfoCardOpen, setIsInfoCardOpen] = useState<boolean>(true);
  const [isEmberMode, setIsEmberMode] = useState<boolean>(false);
  const [symbols, setSymbols] = useState<Symbol[][]>([]);

  const toggleInfoCard = (): void => {
    setIsInfoCardOpen(!isInfoCardOpen);
  };

  const toggleEmberMode = (): void => {
    setIsEmberMode(!isEmberMode);
  };

  return (
    <>
      <Header />
      <Box className={styles["app-container"]}>
        <Box
          className={`${styles.App}${isEmberMode ? ` ${styles.ember}` : ""}`}
        >
          <Box
            className={`${styles["info-card-container"]}${
              isInfoCardOpen ? ` ${styles.open}` : ""
            }`}
          >
            {isInfoCardOpen && (
              <Box className={styles["info-card-animation-container"]}>
                <InfoCardix />
              </Box>
            )}

            <Button
              variant="contained"
              className={`${
                !isEmberMode
                  ? styles["button-opener"]
                  : `${styles["emberMode"]} ${styles["button-opener"]}`
              }`}
              onClick={toggleInfoCard}
              sx={{
                transition: "transform 0.9s ease-in-out",
                transform: isInfoCardOpen
                  ? "translateX(calc(100% - 65px))"
                  : "translateX(0)",
              }}
            >
              {isInfoCardOpen ? "Close CV" : "Open CV"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default App;
