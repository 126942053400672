import React, { createContext, useState } from 'react';

export interface EmberModeContextProps {
  isEmberMode: boolean;
  toggleEmberMode: () => void;
}

export const EmberModeContext = createContext<EmberModeContextProps>({
  isEmberMode: false,
  toggleEmberMode: () => {},
});

interface EmberModeProviderProps {
  children: React.ReactNode;
}

export const EmberModeProvider: React.FC<EmberModeProviderProps> = ({ children }) => {
  const [isEmberMode, setIsEmberMode] = useState(false);

  const toggleEmberMode = () => {
    setIsEmberMode(prevMode => !prevMode);
  };

  const contextValue: EmberModeContextProps = {
    isEmberMode,
    toggleEmberMode,
  };

  return (
    <EmberModeContext.Provider value={contextValue}>
      {children}
    </EmberModeContext.Provider>
  );
};
