import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";
import styles from "./DetailsSection.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const AllTheDetailsEng = () => {
  return (
    <>
      <Box mt={2} className={styles.contactDetails}>
        <Typography variant="h5">
          <strong>Contact Details:</strong>
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Gdańsk, Poland"
              className={styles.contactDetailsItem}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="czupamichal@gmail.com"
              className={styles.contactDetailsItem}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="+48 731 745 566"
              className={styles.contactDetailsItem}
            />
          </ListItem>
          <ListItem>
            <ListItemText className={styles.contactDetailsItem}>
              <a
                href="https://www.linkedin.com/in/michal-czupa-89800b115/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />{" "}
                LinkedIn
              </a>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={styles.contactDetailsItem}>
              <a
                href="https://www.github.com/amplifiedanima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} className={styles.icon} />{" "}
                GitHub
              </a>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={styles.contactDetailsItem}>
              <a
                href="https://www.instagram.com/ourbodyislove"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={styles.icon} />{" "}
                Instagram
              </a>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box mt={2} className={styles.skills}>
        <Typography variant="h5">
          <strong>Tech Stack</strong>
        </Typography>
        <List>
          <ListItem className={styles.listItem}>React / Typescript</ListItem>
          <ListItem className={styles.listItem}>MUI-Materials</ListItem>
          <ListItem className={styles.listItem}>Node / NEST-JS </ListItem>
          <ListItem className={styles.listItem}>MongoDB / PostgresSQL</ListItem>
          <ListItem className={styles.listItem}>GIT</ListItem>
          <ListItem className={styles.listItem}>Context API / Redux</ListItem>
        </List>
      </Box>
      <Box mt={2} className={styles.skills}>
        <Typography variant="h5">
          <strong>Education</strong>
        </Typography>
        <List>
          <ListItem>
            <strong>
              Bachelor's Degree in English Philology (English Linguistics)
            </strong>
          </ListItem>
          <br />

          <ListItem>
            University of technology (Politechnika Koszalińska) 2012-2015,
            Koszalin
          </ListItem>
        </List>
      </Box>
      <Box className={styles.skills}>
        <Typography variant="h5" className={styles.interestsTitle}>
          <strong>Interests</strong>
        </Typography>
        <List>
          <ListItem className={styles.listItem}>Exercise physiology</ListItem>
          <ListItem className={styles.listItem}>
            Strength & Conditioning / Brazilian Jiu-Jitsu
          </ListItem>
          <ListItem className={styles.listItem}>Medical industry</ListItem>
          <ListItem className={styles.listItem}>IT / Web development</ListItem>
        </List>
      </Box>
    </>
  );
};
